/**
 * File story-slider.js.
 *
 */

( function( $ ) {
  // alert(22);

  crsStory = {
    vars : {
      playAudio : 'stop',
      slider : $('#ctwwc-story-slider'),
      sliderHasAudio : false,
      yt_done : false,
      yt_data : [],
      yt_players : [],
      mp3_players : [],
    },
    /* Initialize slider
		  **/
    init : function(){

      // console.log('a');
      if(crsStory.vars.slider.length){

        // screen.orientation.lock('landscape');

        // var storySlider = $('#ctwwc-story-slider');
        crsStory.vars.slider.owlCarousel({
          onInitialized: crsStory.initialized_owl_carousel,
          loop:false,
          // margin:10,
          URLhashListener:true,
          // autoplayHoverPause:true,
          startPosition: 'URLHash',
          // video:true,
          dots:false,
          nav:false,
          navText: ['<div class="story-slider--prev-arrow"></div> <span class="screen-reader-text">Previous</span>','<span class="screen-reader-text">Next</span> <div class="story-slider--next-arrow"></div>'],
          responsive:{
            0:{
              items:1,
              nav:false,
            },
            786:{
              items:1,
              dots:true,
              nav:true,
            }
          }
        });

        // Listen to owl events:
        // crsStory.vars.slider.on('changed.owl.carousel', crsStory.changed_owl_carousel);
        crsStory.vars.slider.on('translated.owl.carousel', crsStory.changed_owl_carousel);
        // crsStory.vars.slider.on('initialized.owl.carousel', crsStory.initialized_owl_carousel);

      }

      if($(".audio-controls").length){
        // attatch clickhandler
        $(".audio-controls").on("click", crsStory.click_on_audioControls);
        // get audio cookie
      }


    },
    /* Handle changed.owl.carousel event
		  **/
    changed_owl_carousel : function(e){

        var currentItem = e.item.index;
        // window.location.hash = currentItem + 1;
        if(e.item.index>0){
          $("body.single-story").addClass("has-story-menu");
        } else if( $("body.single-story").hasClass("has-story-menu") ){
          $("body.single-story").removeClass("has-story-menu");
        }

        if(e.item.index === (crsStory.vars.slider.find(".owl-item").length -1) ){
          $(".ctwwc-story-content").add("footer.content-info").addClass("visible");
          crsStory.vars.slider.addClass("last-slide");
        }else{
          if( $(".ctwwc-story-content").hasClass("visible") ){
            $(".ctwwc-story-content").removeClass("visible");
          }
          if( $("footer.content-info").hasClass("visible") ){
            $("footer.content-info").removeClass("visible");
          }
          if( crsStory.vars.slider.hasClass("last-slide") ){
            crsStory.vars.slider.removeClass("last-slide");
          }
        }

        console.log(currentItem);

        crsStory.stopPlayersYT();
        crsStory.stopPlayersMP3();

        storySlideWrap = crsStory.vars.slider.find(".owl-item").eq(currentItem).find(".story-slide--wrap");
        currentSlideID = storySlideWrap.attr("id");

        if( storySlideWrap.attr("data-video") || storySlideWrap.attr("data-audio") ){
          if( $("#audio-controls").hasClass('disabled') ){
            $("#audio-controls").removeClass('disabled');
          }
        } else {
          if( !$("#audio-controls").hasClass('disabled') ){
            $("#audio-controls").addClass('disabled');
          }
        }

        if(crsStory.vars.playAudio==='play'){

          if( storySlideWrap.attr("data-video") ){
            crsStory.vars.yt_players[currentSlideID].playVideo();
          }

          if( storySlideWrap.attr("data-audio") ){
            crsStory.vars.mp3_players[currentSlideID].play();
          }

        }

        // crsStory.vars.slider.find(".owl-item").eq(currentItem).attr("data-video");
        // crsStory.vars.slider.find(".owl-item").eq(currentItem).attr("data-audio");

    },
    /* Handle initialized.owl.carousel event
		  **/
    initialized_owl_carousel : function(){

      crsStory.vars.slider.find(".owl-item").each(function(e){

        slideId = $(this).find(".story-slide--wrap").attr("id");

        if( $(this).find(".story-slide--wrap").attr("data-video") ){

          slideVideoId = $(this).find(".story-slide--wrap").attr("data-video");
          slideVideoElement = $(this).find(".yt-embed").attr("id");

          crsStory.vars.yt_data[slideId] = {
            id: slideVideoElement,
            height: '390',
            width: '640',
            videoId: slideVideoId,
            playerVars: {rel: 0},
          };

          if(!crsStory.vars.sliderHasAudio){
            crsStory.vars.sliderHasAudio = true;
          }
        }

        if( $(this).find(".story-slide--wrap").attr("data-audio") ){

          slideAudioSrc = $(this).find(".story-slide--wrap").attr("data-audio");

          var sound      = document.createElement('audio');
          // sound.id       = 'audio-player';
          // sound.controls = 'controls';
          sound.src      = slideAudioSrc;
          sound.type     = 'audio/mpeg';

          document.getElementById(slideId).appendChild(sound);
          crsStory.vars.mp3_players[slideId] = sound;

          if(!crsStory.vars.sliderHasAudio){
            crsStory.vars.sliderHasAudio = true;
          }
        }

        if( crsStory.vars.sliderHasAudio && !$("#audio-controls").hasClass("visible") ){
          $("#audio-controls").addClass("visible");
        }

      });
    },
    /* Create YouTube Player
		  **/
    createPlayer : function(playerInfo) {
      return new YT.Player(playerInfo.id, {
        height: playerInfo.height,
        width: playerInfo.width,
        videoId: playerInfo.videoId,
        playerVars: {rel: 0},
      });
    },
    playFirstSlide : function() {
      console.log("playFirstSlide : function() {");
      firstSlideId = $("#ctwwc-story-slider .story-slide--wrap:first-child").attr('id');
      crsStory.playSlideById(firstSlideId);
      // $firstSlide = $("#"+firstSlideId);
      //
      // if( $firstSlide.attr("data-video") ){
      //   crsStory.vars.yt_players[firstSlideId].playVideo();
      // }
      //
      // if( $firstSlide.attr("data-audio") ){
      //   crsStory.vars.mp3_players[firstSlideId].play();
      // }
    },
    playCurrentSlide : function() {
      console.log("crsStory.playCurrentSlide : function() {");
      currentSlideId = $("#ctwwc-story-slider .owl-item.active").find(".story-slide--wrap").attr('id');
      crsStory.playSlideById(currentSlideId);

    },
    playSlideById : function(slideId) {
      console.log("playSlideById : function(slideId) {",slideId);
      $slide = $("#"+slideId);

      if( $slide.attr("data-video") ){
        console.log('if( $slide.attr("data-video") ){');
        crsStory.vars.yt_players[slideId].playVideo();

      }

      if( $slide.attr("data-audio") ){
        console.log('if( $slide.attr("data-audio") ){');
        crsStory.vars.mp3_players[slideId].play();

      }
    },

    /* Handle click on mute-audio button
		  **/
    click_on_audioControls : function(e){

      if('stop' === crsStory.vars.playAudio){
        crsStory.playAudio();
        if(!$(this).hasClass('active')){
          $(this).addClass('active');
        }
        $(this).find('.audio-controls--slider').text('on');
        crsStory.playCurrentSlide();
      }
      else if('play' === crsStory.vars.playAudio){
        crsStory.stopAudio();
        if($(this).hasClass('active')){
          $(this).removeClass('active');
        }
        $(this).find('.audio-controls--slider').text('off');
      }

    },
    muteAudio : function() {

			Cookies.set('playAudio', 'stop', { expires: 14 });
      crsStory.vars.playAudio = 'stop';
      crsStory.mutePlayersYT();
      crsStory.mutePlayersMP3();
      // crsStory.pausePlayersMP3();

      console.log("muteAudio : function() {");
		},
    stopAudio : function() {

			Cookies.set('playAudio', 'stop', { expires: 14 });
      crsStory.vars.playAudio = 'stop';
      crsStory.stopPlayersYT();
      crsStory.stopPlayersMP3();
      // crsStory.pausePlayersMP3();

      console.log("muteAudio : function() {");
		},
		playAudio : function() {

      Cookies.set('playAudio', 'play', { expires: 14 });
      crsStory.vars.playAudio = 'play';
      crsStory.unMutePlayersYT();
      crsStory.unMutePlayersMP3();

      console.log("playAudio : function() {");
		},

    // Stop All YouTube Players
    stopPlayersYT : function(){
      for ( var player in crsStory.vars.yt_players ){

        if (typeof crsStory.vars.yt_players[player].stopVideo === "function") {
            crsStory.vars.yt_players[player].stopVideo();
        }


        // crsStory.vars.yt_players[player].stopVideo();
      }
    },
    // Mute All YouTube Players
    mutePlayersYT : function(){
      for ( var player in crsStory.vars.yt_players ){
        crsStory.vars.yt_players[player].mute();
      }
    },
    // Unmute All YouTube Players
    unMutePlayersYT : function(){
      for ( var player in crsStory.vars.yt_players ){
        crsStory.vars.yt_players[player].unMute();
      }
    },
    // Stop All MP3 Players
    stopPlayersMP3 : function(){
      for ( var player in crsStory.vars.mp3_players ){
        crsStory.vars.mp3_players[player].pause();
        crsStory.vars.mp3_players[player].currentTime = 0;
      }
    },
    // Pause All MP3 Players
    pausePlayersMP3 : function(){
      for ( var player in crsStory.vars.mp3_players ){
        crsStory.vars.mp3_players[player].pause();
      }
    },
    // Mute All MP3 Players
    mutePlayersMP3 : function(){
      for ( var player in crsStory.vars.mp3_players ){
        crsStory.vars.mp3_players[player].muted = true;
      }
    },
    // Unmute All MP3 Players
    unMutePlayersMP3 : function(){
      for ( var player in crsStory.vars.mp3_players ){
        crsStory.vars.mp3_players[player].muted = false;
      }
    }
  }

  $(document).ready(function(){

    if($('#ctwwc-story-slider').length){

      // initialize everything
      crsStory.init();

      // load youtube iframe-api, todo: load conditionally if youtube used on page.
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // manage audio-cookie
      if('undefined' === typeof Cookies.get('playAudio')){
        crsStory.muteAudio();
      } else {
        audioStatus = Cookies.get('playAudio');
        crsStory.vars.playAudio = audioStatus;
        if('play' === audioStatus){
          crsStory.playFirstSlide();
          if(!$("#audio-controls").hasClass('active')){
            $("#audio-controls").addClass('active');
          }
          $("#audio-controls").find('.audio-controls--slider').text('on');
        }
      }

      if($(".story--scroll-indicator").length){
        $(".story--scroll-indicator").click(function(){
          window.scroll({
            top: $(window).height(),
            left: 0,
            behavior: 'smooth'
          });
        });
      }

      console.log(crsStory.vars);

      // $(".video--overlay").click(function(){
      //   $(this).hide();
      //   crsStory.vars.yt_players[ $(this).closest(".story-slide--wrap").attr("id") ].playVideo();
      // });
      // $(".video--overlay").show();
    }

  });


})( jQuery );


function onYouTubeIframeAPIReady() {

  crsStory.vars.yt_done = true;
  // if (typeof crsStory.vars.yt_data === 'undefined') return;

  // Loop Youtube Player Data & populate new array of player instances (one for each slide)
  for ( var player in crsStory.vars.yt_data ){
    var curplayer = crsStory.createPlayer(crsStory.vars.yt_data[player]);
    crsStory.vars.yt_players[player] = curplayer;
  }

}
